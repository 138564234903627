.rotate-screen {
  display: none;
  position: absolute;
  z-index: 5;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: size-rotate(50px) size-rotate(122px) size-rotate(50px) size-rotate(96px);
  background-color: $color-dark-blue;
  color: $color-default-white;

  &__img {
    flex-shrink: 0;
    width: size-rotate(98px);
    height: size-rotate(105px);
    margin-right: size-rotate(40px);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__inner {
    flex-grow: 1;
  }

  &__tite {
    margin: 0;
    font-size: size-rotate(24px);
    line-height: size-rotate(32px);
    color: $color-default-white;
  }

  &__text {
    margin-top: size-rotate(8px);
    margin-bottom: 0;
    font-size: size-rotate(18px);
    line-height: size-rotate(24px);
    color: $color-blue;
  }
}
