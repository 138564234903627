.article {
  p {
    margin: 0;
    padding: 0;
    margin-bottom: size(40px);

    font-size: size(18px);
    line-height: 133%;

    @include mobile-ver {
      margin-bottom: size(32px);

      font-size: size(15px);
      line-height: 120%;
    }


    &:last-child {
      margin: 0;

      @include mobile-ver {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: size(64px);

    @include mobile-ver {
      margin-bottom: size(48px);
    }

    &:last-child {
      margin: 0;

      @include mobile-ver {
        margin-bottom: 0;
      }
    }

    li {
      position: relative;
      padding-left: 15px;

      font-size: size(18px);
      line-height: 133%;

      @include mobile-ver {
        font-size: size(15px);
        line-height: 120%;
      }

      &:not(:last-of-type) {
        margin-bottom: size(16px);

        @include mobile-ver {
          margin-bottom: size(8px);
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: size(7px);
        left: 0;
        width: size(6px);
        height: size(6px);

        border-radius: 50%;
        background-color: $color-green;
      }
    }
  }
}
