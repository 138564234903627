.main-section {
  flex-grow: 1;
  display: flex;
  width: 100%;
  padding-top: size(32px);
  padding-bottom: size(24px);
  background-color: $color-dark-blue;
  color: $color-blue;
  transition: margin-right 1s, margin-left 1s;
  position: relative;

  *::selection {
    color: $color-default-white;
    background-color: $color-blue;
  }

  &--white {
    background-color: $color-default-white;
    color: $color-black-50;
  }

  &--black {
    background-color: $color-black-15;
    color: $color-black-50;
  }

  @include mobile-ver {
    padding-top: size(24px);
    padding-bottom: size(24px);
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__text {
    display: flex;
    flex-direction: column;
    margin-bottom: size(32px);
    font-size: size(72px);
    line-height: size(95px);
    text-transform: uppercase;

    @include mobile-ver {
      font-size: size(40px);
      line-height: size(60px);
    }

    .white {
      color: $color-default-white;
    }

    .black {
      color: $color-black-15;
    }

    p {
      margin: 0;
    }

    &--bottom {
      margin-top: auto;
      margin-bottom: 0;
    }

    .main-section__line {
      margin-top: size(-17px);

      @include mobile-ver {
        margin-top: size(-16px);
      }

      &:first-child {
        margin-top: size(-7px);

        @include mobile-ver {
          margin-top: 0;
        }
      }

      &.main-section__line--mt {
        margin-top: size(-17px);

        @include mobile-ver {
          margin-top: size(-16px);
        }
      }

      &--sm {
        @include mobile-ver {
          margin-top: 0;
          margin-bottom: size(13px);
        }
      }

      &--mb {
        @include mobile-ver {
          margin-bottom: size(8px);
        }
      }
    }
  }

  &__line {
    display: flex;
    overflow: hidden;

    &--sm {
      font-weight: 500;
      font-size: size(12px);
      line-height: size(20px);
      letter-spacing: size(0.7px);
      text-transform: uppercase;
      color: $color-default-white;
      margin-bottom: size(4px);
    }

    &--m-0 {
      margin: 0;

      @include mobile-ver {
        margin: 0;
      }
    }

    &--mt {
      margin-top: size(8px);
    }
  }

  &__line-inner {
    display: flex;
    align-items: center;
  }

  .main-section__line-img {
    margin: 0 size(4px) size(12px);
    overflow: hidden;
    transition: background-color $trans-default, color $trans-default;

    @include mobile-ver {
      margin: 0 size(4px) size(6px);
    }

    &--bg {
      background-color: $color-green;
    }

    &--no-ml {
      margin-left: 0;

      @include mobile-ver {
        margin-left: 0;
      }
    }

    &--no-mr {
      margin-right: 0;

      @include mobile-ver {
        margin-right: 0;
      }
    }

    &--no-mx {
      margin-right: 0;
      margin-left: 0;

      @include mobile-ver {
        margin-right: 0;
        margin-left: 0;
      }
    }

    &--round {
      width: size(52px);
      height: size(52px);
      border-radius: 50%;

      @include mobile-ver {
        width: size(30px);
        height: size(30px);
      }
    }

    &--oval {
      width: size(88px);
      height: size(52px);
      border-radius: size(26px);
      margin-right: size(24px);
      margin-bottom: size(12px);

      @include mobile-ver {
        width: size(53px);
        height: size(30px);
        border-radius: size(15px);
      }
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: size(26px);

      @include mobile-ver {
        border-radius: size(15px);
      }
    }
  }

  &__bottom-text {
    margin-top: auto;
    max-width: size(800px);
    font-size: size(18px);
    line-height: size(24px);
    color: $color-default-white;

    @include mobile-ver {
      font-size: size(15px);
      line-height: size(18px);
    }

    &--2 {
      max-width: none;
    }

    p {
      margin: 0;

      & + p {
        margin-top: size(24px);

        @include mobile-ver {
          margin-top: size(16px);
        }
      }
    }

    .main-section--white & {
      color: $color-black-50;

    }
  }

  &__links {
    margin-top: size(32px);
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;

    @include mobile-ver {
      margin-top: size(24px);
      counter-reset: links-counter;
    }
  }

  &__link-item {
    display: flex;
    counter-increment: links-counter;
    overflow: hidden;

    &--contact {
      align-items: flex-start;
      position: absolute;
      top: size(8px);
      right: size(64px);

      @include mobile-ver {
        position: static;
        top: auto;
        right: auto;
        overflow: visible;

        .main-section__line-inner {
          width: 100%;
        }
      }
    }

    @include mobile-ver {
      position: relative;
    }
  }

  &__link-download {
    display: flex;
    align-items: center;
    font-size: size(72px);
    line-height: size(78px);
    text-transform: uppercase;
    color: $color-blue;
    transition: color $trans-default;

    [href] {
      @include hover {
        color: #cccccc;

        .btn--red {
          background-color: #e84646;
        }
      }
    }
  }

  &__link-icon {
    width: size(52px);
    height: size(52px);
    padding: size(14px);
    margin-left: size(4px);
    margin-bottom: size(14px);
    border-radius: 50%;
    background-color: $color-green;
    color: $color-black-15;
    transition: background-color $trans-default;

    @include mobile-ver {
      width: size(30px);
      height: size(30px);
      padding: size(7px);
    }
  }

  &__link-inner {
    overflow: hidden;
  }

  &__link-contact {
    display: block;
    overflow: hidden;

    @include mobile-ver {
      color: $color-green;

      @include hover {
        color: $color-default-white;
      }
    }

    .main-section__line-img {
      display: none;

      @include mobile-ver {
        display: block;
        background-color: $color-green;
        padding: size(4px);
      }
    }
  }

  &__link {
    @include mobile-ver {
      display: flex;
      align-items: center;
      padding: 0;
      border-radius: 0;
      background-color: transparent;
      font-weight: normal;
      font-size: size(40px);
      line-height: size(44px);
      text-transform: uppercase;
      transition: color $trans-default;

      &[href] {
        @include hover {
          color: #cccccc;
          background-color: transparent;

          .btn--red {
            background-color: #e84646;
          }
        }
      }
    }
  }

  &__contacts {
    margin: size(32px) size(-64px) size(-24px);

    @include mobile-ver {
      margin: size(24px) size(-16px) size(-24px);
    }
  }

  &__pin {
    display: flex;

    .main-section__link-icon {
      flex-shrink: 0;
      margin: 0;
      margin-right: size(8px);
    }
  }

  &__pin-icon {
    width: size(40px);
    height: size(40px);
    padding: size(10px);
    border-radius: 50%;
    background-color: $color-green;
    margin-right: size(8px);
    margin-top: size(5px);
  }

  &__pin-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    font-size: size(18px);
    line-height: size(24px);
  }

  &__btn {
    margin: 0 size(11px) size(11px);


    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &--mr {
      &:nth-child(n) {
        margin-right: size(11px);
      }
    }
  }

  &__schedule {
    margin-left: size(28px);
    margin-bottom: size(11px);
  }

  &__services {
    @include mobile-ver {
      margin-bottom: size(4px);
      margin-left: size(4px);
    }
  }

  &__email {
    display: flex;
    align-items: center;
  }

  &__sub {
    margin: 0 size(6px);
    bottom: size(27px);
    top: auto;
    font-weight: 500;
    font-size: size(12px);
    line-height: size(20px);
    letter-spacing: size(0.7px);
    text-transform: uppercase;
  }

  .link {
    &-white {
      color: $color-default-white;
      transition: color $trans-default;

      &[href] {
        @include hover {
          color: $color-green;

          .main-section__link-icon {
            background-color: $color-default-white
          }
        }
      }
    }

    &-blue {
      color: $color-blue;
      transition: color $trans-default;

      &[href] {
        @include hover {
          color: #7d97bd;

          .btn--red {
            background-color: #e84646;
          }
        }
      }
    }

    &-black {
      color: $color-default-black;
      transition: color $trans-default;

      &[href] {
        @include hover {
          color: $color-green;

          .main-section__line-img {
            background-color: #69E897;
          }

          .main-section__link-icon {
            background-color: $color-black-15;
            color: $color-green;
          }
        }
      }

      &.no-hover {
        @include hover {
          color: $color-default-black;
        }
      }
    }

    &-green {
      color: $color-green;
      transition: color $trans-default;

      @include hover {
        color: $color-black-15;
      }

      @include mobile-ver {
        @include hover {
          color: $color-green;
        }
      }
    }
  }

  &__inner-page {
    max-width: size(875px);
    margin: 0 auto;
    padding-top: size(145px);
    padding-right: size(110px);
    padding-bottom: size(180px);

    @include mobile-ver {
      padding-top: size(65px);
      padding-bottom: size(92px);
      padding-right: 0;
    }
  }

  &__row {
    width: 100%;
    display: flex;
  }

  &__col {
    width: 50%;

    &:first-child {
      padding-right: size(50px);
    }
  }

  &__social-icon {
    width: size(24px);
    height: size(24px);
    margin-right: size(6px);

    @include mobile-ver {
      width: size(20px);
      height: size(20px);
      margin-right: size(4px);
    }
  }
}
