/* stylelint-disable */
@function size($size-in-px) {
  @return $size-in-px / $fz-default * 1rem;
}

@function size-rotate($size-in-px) {
  @return $size-in-px / $vp-757 * 100vw;
}

@function size-desktop($size-in-px) {
  @return $size-in-px / $vp-1440 * 100vw;
}

@function size-mobile($size-in-px) {
  @return $size-in-px / $vp-375 * 100vw;
}
/* stylelint-enable */
