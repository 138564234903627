.form {
  width: 50vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: size(50px) size(64px) size(44px);
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  background-color: $color-green-vogue;
  z-index: 2;
  font-size: size(16px);
  line-height: size(22px);
  color: $color-fountain-blue;
  transform: translateX(100%);
  // transition: transform 1s;

  @include mobile-ver {
    width: 100vw;
    padding: size(24px) size(16px);
  }

  &__inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &__title {
    margin-top: 0;
    margin-bottom: auto;
    padding-right: size(70px);
    padding-bottom: size(11px);
    font-size: size(28px);
    line-height: size(32px);
    font-weight: 400;
    color: $color-default-white;

    @include mobile-ver {
      padding-right: size(50px);
      padding-bottom: size(10px);
      margin-bottom: auto;
      font-size: size(22px);
      line-height: size(24px);
    }
  }

  &__close {
    width: size(52px);
    height: size(52px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: size(40px);
    right: size(64px);
    background-color: $color-default-white;
    border-radius: 50%;
    border: none;
    color: $color-dark-blue;
    transition: background-color $trans-default;
    cursor: pointer;
    z-index: 2;

    svg {
      width: size(24px);
      height: size(24px);
      flex-shrink: 0;
    }

    @include hover {
      background-color: $color-green;
    }

    @include mobile-ver {
      width: size(40px);
      height: size(40px);
      top: size(16px);
      right: size(16px);

      svg {
        width: size(20px);
        height: size(20px);
      }
    }
  }

  // &__form {
  //   margin: auto 0;
  // }

  &__wrap {
    display: flex;
    flex-direction: column;
    border: size(1px) solid $color-blumine;

    @include mobile-ver {
      margin: size(20px) 0 size(32px);
    }
  }

  &__group {
    padding: size(24px);
    border-bottom: size(1px) solid $color-blumine;
    max-height: size(154px);
    overflow: auto;

    @include mobile-ver {
      padding: size(24px) size(16px);
    }
  }

  &__checkbox {
    display: block;
    cursor: pointer;

    & + & {
      margin-top: size(16px);
    }

    input {
      display: none;
    }

    @include hover {
      .form__checkbox-span::before {
        border: size(1px) solid $color-default-white;
      }
    }
  }

  &__checkbox-span {
    display: flex;
    align-items: center;
    color: $color-default-white;

    &::before {
      content: '';
      width: size(24px);
      height: size(24px);
      margin-right: size(8px);
      border: size(1px) solid $color-blumine;
      border-radius: 50%;
      transition: border-color $trans-default, background-color $trans-default;

      input:checked ~ & {
        background-image: url('../img/svg/check.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: size(16px) size(16px);
        background-color: $color-default-white;
        border: size(1px) solid $color-default-white;
      }
    }
  }

  &__checkbox-inner {
    // padding-top: size(3px);
    margin-bottom: -3px;
  }

  &__row {
    display: flex;
    border-bottom: size(1px) solid $color-blumine;

    @include mobile-ver {
      display: block;
      border-bottom: none;
    }
  }

  &__input {
    flex-grow: 1;
    width: 50%;

    @include mobile-ver {
      width: 100%;
      border-bottom: size(1px) solid $color-blumine;
    }

    & + & {
      border-left: size(1px) solid $color-blumine;

      @include mobile-ver {
        border-left: none;
      }
    }

    &--textarea {
      width: 100%;

      @include mobile-ver {
        width: 100%;
      }
    }

    &--desktop {
      @include mobile-ver {
        display: none;
      }
    }

    &--sm-nobd {
      @include mobile-ver {
        border-bottom: none;
      }
    }

    .custom-input--textarea {
      @include mobile-ver {
        border-bottom: none;
      }
    }
  }

  &__submit {
    margin-top: size(32px);

    @include mobile-ver {
      margin-top: 0;
    }
  }

  &__conditions {
    max-width: size(378px);
    margin-bottom: 0;
    margin-top: auto;
    font-size: size(14px);
    line-height: size(18px);
    color: $color-fountain-blue;
  }

  &__conditions-link {
    color: $color-fountain-blue;
    transition: color $trans-default;

    &[href] {
      @include hover {
        color: $color-default-white;
      }
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: size(50px) size(64px) size(44px);
    background-color: $color-green-vogue;
    transform: translateX(100%);
    // transition: opacity 1s;
    opacity: 0;
    z-index: 1;

    @include mobile-ver {
      padding: size(24px) size(16px);
    }
  }

  &__success-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: 0;
    background-color: $color-green-vogue;
  }

  &__success-title {
    margin-top: 0;
    margin-bottom: size(16px);
    font-size: size(28px);
    line-height: size(32px);
    color: $color-default-white;

    @include mobile-ver {
      font-size: size(22px);
      line-height: size(24px);
    }
  }

  &__text {
    margin: 0;
    max-width: size(340px);
    font-size: size(18px);
    line-height: size(24px);

    @include mobile-ver {
      font-size: size(16px);
      line-height: size(22px);
    }
  }

  &__success-button {
    margin-top: size(40px);

    @include mobile-ver {
      margin-top: size(32px);
    }
  }
}
