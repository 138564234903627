@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Atyp Display";

  font-display: swap;
  src:
    url("../fonts/AtypDisplay-Regular.woff2") format("woff2"),
    url("../fonts/AtypDisplay-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Atyp Display";

  font-display: swap;
  src:
    url("../fonts/AtypDisplay-Medium.woff2") format("woff2"),
    url("../fonts/AtypDisplay-Medium.woff") format("woff");
}
