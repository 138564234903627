@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      @content;
    }
  }
}

@mixin mobile-ver {
  .mobile-ver & {
    @content;
  }
}

// ---------------------------------

// не нужно добавлять свои миксины для ресета списков, обнуления кнопок и прочие
// этим вы усложняете работу команды, проверку вашего кода и будущую поддержку проекта
