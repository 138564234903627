.btn {
  border: none;
  padding: size(17px) size(32px) size(15px);
  background-color: $color-green;
  border-radius: size(40px);
  transition: background-color $trans-default;
  font-weight: 500;
  font-size: size(12px);
  line-height: size(20px);
  letter-spacing: size(0.7px);
  text-transform: uppercase;
  color: $color-dark-blue;

  @include mobile-ver {
    padding: size(6px) size(16px) size(5px);
  }

  &:disabled {
    background-color: $color-fountain-blue;
    color: rgba($color-default-white, 0.5);
  }

  &[href],
  &[type]:not(:disabled) {
    cursor: pointer;

    @include hover {
      &:not([data-hover]) {
        background-color: $color-default-white;
        color: $color-dark-blue;
      }
    }
  }

  &.hover {
    background-color: $color-default-white;
    color: $color-dark-blue;
  }

  &--white {
    background-color: $color-default-white;
    border: 1px solid rgba($color-black-15, 0.1);
    color: $color-black-15;

    &[href] {
      @include hover {
        &:not([data-hover]) {
          background-color: $color-green;
          color: $color-black-15;
        }
      }
    }

    &.hover {
      background-color: $color-green;
      color: $color-black-15;
    }
  }

  &--red {
    background-color: $color-red;
    color: $color-default-white;

    &[href] {
      @include hover {
        &:not([data-hover]) {
          background-color: $color-green;
          color: $color-black-15;
        }
      }
    }

    &.hover {
      background-color: $color-green;
      color: $color-black-15;
    }
  }

  &--gray {
    background-color: $color-blue;
    color: rgba($color-default-white, 0.5)
  }

  &--sm {
    @include mobile-ver {
      padding: size(6px) size(12px) size(5px);
    }
  }

  &--lg-mobile {
    @include mobile-ver {
      padding: size(17px) size(32px);
    }
  }
}
