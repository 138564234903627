.custom-input {
  display: block;
  position: relative;

  input,
  textarea {
    width: 100%;
    padding: size(35px) size(24px) size(13px);
    background-color: transparent;
    border: none;
    font-size: size(16px);
    line-height: size(22px);
    color: $color-default-white;
    transition: color $trans-default;

    &::placeholder {
      color: $color-fountain-blue;
      transition: color $trans-default;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 50px $color-green-vogue;
      -webkit-text-fill-color: $color-default-white;
      color: $color-default-white;
    }

    &:focus {
      outline: none;
    }

    @include hover {
      &::placeholder {
        color: $color-default-white;
      }
    }

    @include mobile-ver {
      padding-left: size(16px);
      padding-right: size(16px);
    }
  }

  textarea {
    min-height: size(150px);
    max-height: size(150px);
    padding: size(24px) size(24px);

    @include mobile-ver {
      padding: size(24px) size(16px);
    }
  }

  &.is-invalid {
    input {
      color: $color-flamingo;

      &::placeholder {
        color: $color-flamingo;
      }

      @include hover {
        color: $color-default-white;

        &::placeholder {
          color: $color-flamingo;
        }
      }
    }

    .custom-input__label {
      color: $color-flamingo;
    }
  }

  &--required {
    &::before {
      content: '';
      width: size(4px);
      height: size(4px);
      border-radius: 50%;
      background-color: $color-fountain-blue;
      position: absolute;
      top: size(16px);
      right: size(16px);
      transition: background-color $trans-default;
    }

    &.is-invalid {
      &::before {
        background-color: $color-flamingo;
      }
    }
  }

  &--textarea {
    display: flex;
    width: 100%;

    @include mobile-ver {
      width: 100%;
    }
  }

  &__label {
    position: absolute;
    left: size(24px);
    top: size(23px);
    color: $color-fountain-blue;
    pointer-events: none;
    transition: transform $trans-default;
    transform-origin: left top;

    @include mobile-ver {
      left: size(16px);
    }

    input:focus + &,
    &.moved {
      transform: translateY(#{size(-10px)}) scale(.85);
    }


    textarea:focus + &,
    textarea + &.moved {
      opacity: 0;
    }
  }
}
