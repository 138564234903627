.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* stylelint-disable-next-line */
  min-height: calc(100 * var(--vh, 1vh));
  overflow: hidden;

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &--form {
    .main-section {
      // transform: translate3d(-50vw, 0, 0);
      margin-left: -50vw;
      // margin-right: 50vw;
    }

    &.mobile-ver .main-section {
      // transform: translate3d(-100vw, 0, 0);
      margin-left: -100vw;
      // margin-right: 100vw;
    }

    // .form {
    //   transform: none;
    // }
  }
}

.wrapper-bg {
  display: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 0.05556rem * 4);
  height: calc(100% + 0.05556rem * 4);
  background-color: $color-dark-blue;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.scroll-lock-ios {
  position: fixed;

  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.no-transition {
  /* stylelint-disable-next-line */
  transition: none !important;
}

.mobile {
  display: none;

  @include mobile-ver {
    display: block;
  }
}

.desktop {
  @include mobile-ver {
    display: none;
  }
}

[data-line-animate-desktop],
[data-line-animate-mobile] {
  transform: translateY(-150%);

  .init & {
    transform: none;
  }
}
